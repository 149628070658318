"use client";

import { Button } from "@/components/ui/button";
export const SafeExit = ({
  url
}: {
  url?: string;
}) => {
  const onClick = () => {
    const target = url || "https://google.com";
    window.open(target, "_blank");
    window.document.body.style.opacity = "0";
    window.document.title = 'New Tab';
    // clears current frame only
    while (document.firstChild) {
      document.removeChild(document.firstChild);
    }
    if (history && history.replaceState) {
      history.replaceState(null, 'Home', '/');
    }
    // href = this.href || target.href;
    location.replace("/");
    location.replace(target);
    return false;
  };
  return <Button variant="primary" className="h-6 w-[80px] whitespace-nowrap rounded-b-xl rounded-t-none p-0 px-2 leading-tight shadow-lg" onClick={onClick} data-sentry-element="Button" data-sentry-component="SafeExit" data-sentry-source-file="safe-exit.tsx">
      Safe Exit
    </Button>;
};