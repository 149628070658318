"use client";

import * as React from "react";
import { Check, ChevronDown, ChevronUp, ChevronsUpDown, X } from "lucide-react";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Label } from "@/components/ui/label";
import { Popover, PopoverContent, PopoverTrigger } from "@/components/ui/popover";
type ComboboxProps = {
  items?: {
    id?: number;
    value: string;
    label: string;
    props?: any;
  }[];
  selectedValues?: Record<string, string>;
  onSelect: (values: Record<string, string>) => void;
  label: string;
  defaultLabel?: string;
  icon?: React.ReactElement;
  inlineLabel?: boolean;
  noLabel?: boolean;
  onChange?: (value: string) => void;
  multiselect?: boolean;
  classNames?: {
    container?: string;
    label?: string;
  };
  node?: (item: any) => React.ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
  hideInput?: boolean;
};
export function Combobox({
  items = [],
  selectedValues = {},
  onSelect,
  onChange,
  label,
  icon,
  multiselect,
  defaultLabel,
  noLabel,
  inlineLabel,
  hideInput = false,
  classNames,
  node,
  onFocus,
  onBlur
}: ComboboxProps) {
  const [open, setOpen] = React.useState(false);
  const [values, setValues] = React.useState(selectedValues);
  const [searchValue, setSearchValue] = React.useState("");
  const stringifiedValues = JSON.stringify(selectedValues);
  React.useEffect(() => {
    if (stringifiedValues) {
      setValues(JSON.parse(stringifiedValues));
    }
  }, [stringifiedValues]);
  const filteredItems = React.useMemo(() => {
    return items.filter(item => item.label.toLowerCase().includes(searchValue.toLowerCase()));
  }, [items, searchValue]);
  const DropdownIcons = () => {
    if (hideInput) {
      return open ? <ChevronUp className="ml-2 size-4 shrink-0 text-foreground" /> : <ChevronDown className="ml-2 size-4 shrink-0 text-foreground" />;
    }
    return open ? <X className="ml-2 size-4 shrink-0 text-foreground" data-sentry-element="X" data-sentry-component="DropdownIcons" data-sentry-source-file="combobox.tsx" /> : <ChevronsUpDown className="ml-2 size-4 shrink-0 text-foreground" data-sentry-element="ChevronsUpDown" data-sentry-component="DropdownIcons" data-sentry-source-file="combobox.tsx" />;
  };
  const labels = () => {
    if (Object.keys(values).length === 0) return inlineLabel ? label : defaultLabel || ``;
    const arrLabels = Object.entries(values).map(([_, value]) => value);
    if (arrLabels.length < 3) return arrLabels.join(", ");
    return inlineLabel ? `${label}: ${arrLabels.length} selected` : `${arrLabels.length} selected`;
  };
  const handleSelect = (item: typeof items[0]) => {
    const existingEntry = Object.entries(values).find(([v]) => v === item.value);
    if (existingEntry) {
      const newValues = {
        ...values
      };
      delete newValues[existingEntry[0]];
      setValues(newValues);
      onSelect(newValues);
    } else {
      if (multiselect) {
        const newValues = {
          ...values,
          [item.value]: item.label
        };
        setValues(newValues);
        onSelect(newValues);
      } else {
        const newValues = {
          [item.value]: item.label
        };
        setValues(newValues);
        onSelect(newValues);
        setOpen(false);
      }
    }
  };
  return <Popover open={open} onOpenChange={setOpen} data-sentry-element="Popover" data-sentry-component="Combobox" data-sentry-source-file="combobox.tsx">
      <PopoverTrigger className="w-full rounded-xl p-2" asChild data-sentry-element="PopoverTrigger" data-sentry-source-file="combobox.tsx" data-sentry-element="PopoverTrigger" data-sentry-element="PopoverTrigger">
        <div className={`flex w-full flex-col ${classNames?.container}`}>
          {!inlineLabel && !noLabel && <Label className="text-xs font-bold uppercase text-foreground">
              {label}
            </Label>}
          <Button variant="ghost" role="combobox" aria-expanded={open} className="w-full justify-between rounded-lg px-0 text-primary" data-sentry-element="Button" data-sentry-source-file="combobox.tsx" data-sentry-element="Button" data-sentry-element="Button">
            <div className="flex items-center">
              {icon && <span className="mr-2 opacity-50">{icon}</span>}
              <span className={cn("text-left", classNames?.label)}>
                {typeof values === "string" ? values : labels()}
              </span>
            </div>
            <DropdownIcons data-sentry-element="DropdownIcons" data-sentry-source-file="combobox.tsx" data-sentry-element="DropdownIcons" data-sentry-element="DropdownIcons" />
          </Button>
        </div>
      </PopoverTrigger>
      <PopoverContent className="max-h-[300px] w-[--radix-popover-trigger-width] min-w-[var(--radix-popover-trigger-width)] overflow-auto p-0" align="start" data-sentry-element="PopoverContent" data-sentry-source-file="combobox.tsx" data-sentry-element="PopoverContent" data-sentry-element="PopoverContent">
        {!hideInput && <div className="sticky top-0 bg-background p-2">
            <input className="flex h-10 w-full rounded-md border border-input bg-background px-3 py-2 text-sm ring-offset-background file:border-0 file:bg-transparent file:text-sm file:font-medium placeholder:text-muted-foreground focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:cursor-not-allowed disabled:opacity-50" placeholder={label.toLowerCase().includes("search") ? label : `Search ${label}`} value={searchValue} onChange={e => {
          setSearchValue(e.target.value);
          onChange?.(e.target.value);
        }} onFocus={onFocus} onBlur={onBlur} />
          </div>}
        <div className="p-2">
          {filteredItems.length === 0 ? <div className="py-6 text-center text-sm text-muted-foreground">
              No results found.
            </div> : filteredItems.map((item, idx) => <div key={`${idx}-${item.value}`} onClick={() => handleSelect(item)} className={cn("flex cursor-pointer items-center rounded-sm px-3 py-2 text-sm outline-none hover:bg-accent hover:text-accent-foreground", values[item.value] && "bg-accent text-accent-foreground")}>
                {multiselect && <Check className={cn("mr-2 size-4", values[item.value] ? "opacity-100" : "opacity-0")} />}
                {node ? node(item) : item.label.includes(">") ? <div className="flex w-full justify-between">
                    <div className="text-xs">
                      {item.label.split(">")[0].trim()}
                    </div>
                    <div className="text-xs font-bold">
                      {item.label.split(">")[1].trim()}
                    </div>
                  </div> : item.label}
              </div>)}
        </div>
      </PopoverContent>
    </Popover>;
}