import * as React from "react";
import Link from "next/link";
import { NavItem } from "@/types/nav";
import Image from "next/image";
interface MainNavProps {
  items?: NavItem[];
}
export function MainNav({
  items
}: MainNavProps) {
  return <div className="flex gap-6 md:gap-10 shrink-0" data-sentry-component="MainNav" data-sentry-source-file="main-nav.tsx">
      <Link href="/" className="hidden items-center md:mt-2 md:flex" data-sentry-element="Link" data-sentry-source-file="main-nav.tsx" data-sentry-element="Link" data-sentry-element="Link">
        <Image className="hidden dark:block" src="/logo-dark.svg" alt="Logo" width={200} height={40} data-sentry-element="Image" data-sentry-source-file="main-nav.tsx" data-sentry-element="Image" data-sentry-element="Image" />
        <Image className="dark:hidden" src="/logo-light.svg" alt="Logo" width={200} height={40} data-sentry-element="Image" data-sentry-source-file="main-nav.tsx" data-sentry-element="Image" data-sentry-element="Image" />
      </Link>
      <Link href="/" className="items-center md:hidden" data-sentry-element="Link" data-sentry-source-file="main-nav.tsx" data-sentry-element="Link" data-sentry-element="Link">
        <Image className="hidden dark:block" src="/logo-sm-dark.svg" alt="Logo" width={40} height={40} data-sentry-element="Image" data-sentry-source-file="main-nav.tsx" data-sentry-element="Image" data-sentry-element="Image" />
        <Image className="dark:hidden" src="/logo-sm-light.svg" alt="Logo" width={40} height={40} data-sentry-element="Image" data-sentry-source-file="main-nav.tsx" data-sentry-element="Image" data-sentry-element="Image" />
      </Link>
    </div>;
}