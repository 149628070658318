"use client";

import { useEffect } from "react";
import { datadogRum } from "@datadog/browser-rum";
import { useJerkSession } from "@/jerkClient";
// import { datadogLogs } from "@datadog/browser-logs"

export default function DatadogInit() {
  const {
    session
  } = useJerkSession();
  useEffect(() => {
    if (process.env.NODE_ENV === "production") {
      datadogRum.init({
        applicationId: "f50013e7-78e6-495c-9f46-a1701e63e476",
        clientToken: "pub357a55cee493a804bc0dd8d63ac520b8",
        site: "datadoghq.com",
        service: "kotcafe-prod-frontend",
        env: "prod",
        // proxy: '/api/ddp',
        // Specify a version number to identify the deployed version of your application in Datadog
        // version: '1.0.0',
        sessionSampleRate: 100,
        sessionReplaySampleRate: 100,
        trackUserInteractions: true,
        trackResources: true,
        trackLongTasks: true,
        defaultPrivacyLevel: "mask-user-input"
      });
      if (session.activeUserAccount) {
        datadogRum.setUser({
          id: session.activeUserAccountId + "",
          name: session.activeCustomer?.nickname || session.activeEscortProfile?.nickname,
          email: session.email
        });
      }

      // datadogLogs.init({
      //   clientToken: 'pub357a55cee493a804bc0dd8d63ac520b8',
      //   site: "datadoghq.com",
      //   service: "kotcafe_next",
      //   env: 'prod',
      //   forwardErrorsToLogs: true,
      //   sessionSampleRate: 100,
      // })
    }
  }, []);
  return null;
}