import { cn } from "@/lib/utils";
export type HeadingProps = React.HTMLAttributes<HTMLHeadingElement> & {
  children: React.ReactNode;
  as: 'h1' | 'h2' | 'h3' | 'h4' | 'h5' | 'h6' | any;
};
export const Heading = ({
  children,
  as,
  className,
  ...props
}: HeadingProps) => {
  const Element = as;
  return <Element className={cn("heading text-card-foreground", className)} {...props} data-sentry-element="Element" data-sentry-component="Heading" data-sentry-source-file="heading.tsx">
      <div>{children}</div>
    </Element>;
};