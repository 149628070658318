"use client";

import { Toast, ToastClose, ToastDescription, ToastProvider, ToastTitle, ToastViewport } from "@/components/ui/toast";
import { useToast } from "@/components/ui/use-toast";
export function Toaster() {
  const {
    toasts
  } = useToast();
  return <ToastProvider data-sentry-element="ToastProvider" data-sentry-component="Toaster" data-sentry-source-file="toaster.tsx">
      {toasts.map(function ({
      id,
      title,
      description,
      action,
      ...props
    }) {
      return <Toast key={id} {...props}>
            <div className="grid gap-1">
              {title && <ToastTitle>{title}</ToastTitle>}
              {description && <ToastDescription>{description}</ToastDescription>}
            </div>
            {action}
            <ToastClose />
          </Toast>;
    })}
      <ToastViewport data-sentry-element="ToastViewport" data-sentry-source-file="toaster.tsx" data-sentry-element="ToastViewport" data-sentry-element="ToastViewport" />
    </ToastProvider>;
}