"use client";

import * as React from "react";
import Image from "next/image";
import Link from "next/link";
import { HelpCircle, Home, Info, PhoneCall, ShoppingBag } from "lucide-react";
//@ts-ignore
import { useIntercom } from "react-use-intercom";
import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/icons";
import { ThemeToggle } from "@/components/theme-toggle";
import { getAllPromotions, Promotion } from "@/lib/contentful";
import { useEffect, useState } from "react";
export function MainMenu() {
  const [showMenu, setShowMenu] = React.useState(false);
  const {
    show
  } = useIntercom();
  const toggleMenu = () => setShowMenu(!showMenu);
  const [promotions, setPromotions] = useState<Promotion[]>([]);
  useEffect(() => {
    const fetchPromotions = async () => {
      const promotions = ((await getAllPromotions()) as Promotion[]).filter(x => x.sidebar);
      setPromotions(promotions);
    };
    fetchPromotions();
  }, []);
  return <div className="relative" data-sentry-component="MainMenu" data-sentry-source-file="main-menu.tsx">
      <Button variant="ghost" size="sm" onClick={toggleMenu} data-sentry-element="Button" data-sentry-source-file="main-menu.tsx" data-sentry-element="Button" data-sentry-element="Button">
        <Icons.menu className={cn("rotate-0 scale-100 transition-all", {
        "-rotate-90 scale-0": showMenu
      })} data-sentry-element="unknown" data-sentry-source-file="main-menu.tsx" data-sentry-element="unknown" data-sentry-element="unknown" />
        <Icons.close className={cn("absolute rotate-90 scale-0 transition-all", {
        "rotate-0 scale-100": showMenu
      })} data-sentry-element="unknown" data-sentry-source-file="main-menu.tsx" data-sentry-element="unknown" data-sentry-element="unknown" />
        <span className="sr-only">Toggle menu</span>
      </Button>
      {showMenu && <div className="fixed left-0 top-16 flex size-full justify-end">
          <div className="absolute right-0 top-0 size-full bg-background/50 backdrop-blur-md" onClick={toggleMenu} />
          <div className={cn("md:ml-3/4 relative flex h-full w-3/4 translate-x-full flex-col bg-muted px-8 text-primary transition-all md:w-1/3", {
        "translate-x-0": showMenu
      })}>
            <ul className="mt-4 flex h-full flex-col" onClick={toggleMenu}>
              <li className="flex h-16 flex-row items-center">
                <Home className="mr-4 opacity-50" />
                <Link href="/">Home</Link>
              </li>
              <li className="flex h-16 flex-row items-center">
                <Info className="mr-4 opacity-50" />
                <Link href="/help/how-it-works">How it Works</Link>
              </li>
              <li className="flex h-16 flex-row items-center">
                <HelpCircle className="mr-4 opacity-50" />
                <Link href="/help">Help Centre</Link>
              </li>
              
              <li className="flex h-16 flex-row items-center">
                <PhoneCall className="mr-4 opacity-50" />
                <Link href="#" onClick={() => {
              show();
              toggleMenu();
            }}>
                  Contact Us
                </Link>
              </li>
              <li className="flex h-16 flex-row items-center">
                <ShoppingBag className="mr-4 opacity-50" />
                <Link href="https://shop.dalliancedomain.com.au" target="_blank" rel="noopener noreferrer">
                  Shop
                </Link>
              </li>
              {promotions.map((promo, index) => <li key={index} className="flex h-16 flex-row items-center">
                  <Link href={`/pro/${promo.slug}`}>{promo.title}</Link>
                </li>)}                  
            </ul>
            <div className="absolute bottom-20 left-0 flex w-full flex-col items-center justify-center transition-all">
              <div className="mb-8 flex w-full items-center px-8">
                <ThemeToggle />
                <div>Light/Dark Mode</div>
              </div>
              <Image className="hidden dark:block" src="/logo-dark.svg" alt="Logo" width={200} height={40} />
              <Image className="dark:hidden" src="/logo-light.svg" alt="Logo" width={200} height={40} />
            </div>
          </div>
        </div>}
    </div>;
}