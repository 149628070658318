"use client";

import React from 'react';
function Error({
  statusCode
}: {
  statusCode: number;
}) {
  return <div style={{
    padding: '50px',
    textAlign: 'center'
  }} data-sentry-component="Error" data-sentry-source-file="error.tsx">
      <h1>Oops! Something went wrong.</h1>
      <p>
        {statusCode ? `An error ${statusCode} occurred on server` : 'An error occurred on client'}
      </p>
      <p>Please try refreshing the page or contact support if the issue persists.</p>
    </div>;
}
Error.getInitialProps = ({
  res,
  err
}: {
  res?: {
    statusCode: number;
  };
  err?: {
    statusCode: number;
  };
}) => {
  const statusCode = res ? res.statusCode : err ? err.statusCode : 404;
  return {
    statusCode
  };
};
export default Error;
export class ErrorBoundary extends React.Component {
  state = {
    hasError: false,
    error: null
  };
  static getDerivedStateFromError(error: Error) {
    return {
      hasError: true,
      error
    };
  }
  componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
    console.error("Error caught by ErrorBoundary: ", error, errorInfo);
  }
  resetErrorBoundary = () => {
    this.setState({
      hasError: false,
      error: null
    });
  };
  render() {
    if (this.state.hasError) {
      return <Error statusCode={500} data-sentry-element="Error" data-sentry-component="ErrorBoundary" data-sentry-source-file="error.tsx" />;
    }
    // @ts-ignore
    return this.props.children as React.ReactNode;
  }
}