import { useEffect, useState } from "react";
import { Combobox as BaseCombobox } from "./ui/primitives/combobox";
type ComboboxProps = {
  id?: string;
  items?: {
    value: string;
    label: string;
    props?: any;
  }[];
  selectedValues?: Record<string, string>;
  onSelect: (values: Record<string, string>) => void;
  label: string;
  icon?: React.ReactElement;
  inlineLabel?: boolean;
  endpoint?: string;
  valueKey?: string;
  labelKey?: string;
  noLabel?: boolean;
  hideInput?: boolean;
  defaultLabel?: string;
  multiselect?: boolean;
  dynamicFetch?: boolean;
  node?: (item: any) => React.ReactNode;
  onFocus?: () => void;
  onBlur?: () => void;
  classNames?: {
    container?: string;
    label?: string;
    item?: string;
    wrapper?: string;
  };
};
export const Combobox = (props: ComboboxProps) => {
  const [items, setItems] = useState<any[]>([]);
  const fetchItems = (v?: string) => {
    if (!props.endpoint) {
      setItems(props.items || []);
      return;
    }
    fetch(`${props.endpoint}${v}`).then(res => res.json()).then(res => {
      const items = res.map((item: any) => ({
        id: item["id"],
        value: item[props.valueKey || "value"],
        label: item[props.labelKey || "label"],
        props: item["props"]
      }));
      setItems(items);
    });
  };
  useEffect(() => fetchItems(""), []);
  return <BaseCombobox {...props} items={items} onChange={fetchItems} data-sentry-element="BaseCombobox" data-sentry-component="Combobox" data-sentry-source-file="combobox.tsx" />;
};