"use client";

import { useEffect, useState } from "react";
//@ts-ignore
import { IntercomProps, useIntercom } from "react-use-intercom";
import { Button } from "@/components/ui/button";
import { useJerkSession } from "@/jerkClient";
export { IntercomProvider } from "react-use-intercom";
export default function Intercom() {
  const [open, setOpen] = useState(0);
  const {
    session,
    routerPush
  } = useJerkSession();
  const {
    boot,
    shutdown,
    show,
    hide
  } = useIntercom();
  useEffect(() => {
    let options: IntercomProps = {
      verticalPadding: 20,
      hideDefaultLauncher: true
    };
    if (session?.clerkUserId) {
      options = {
        hideDefaultLauncher: true,
        email: session.email,
        user_id: session.clerkUserId,
        verticalPadding: 20
      } as IntercomProps;
      const acc = session.activeUserAccount;
      if (!acc) return;
      if (session.userType === "customer") {
        options.name = session.activeUserAccount?.nickname;
        options.avatar = {
          type: "avatar",
          imageUrl: acc.profilePic
        };
      } else {
        const seller = session.activeSeller;
        options.name = session.activeEscortProfile?.nickname || seller?.name || "??";
        options.avatar = {
          type: "avatar",
          imageUrl: session.activeEscortProfile?.profilePic
        };
      }
      setTimeout(() => {
        boot(options);
      }, 2000);
    } else {
      setTimeout(() => {
        boot(options);
      }, 2000);
    }
    return () => {
      shutdown();
    };
  }, [boot, shutdown, session]);
  useEffect(() => {
    if (open) {
      show();
    } else {
      hide();
    }
  }, [open, show, hide]);
  return <div className="z-40 fixed bottom-20  right-1" data-sentry-component="Intercom" data-sentry-source-file="intercom.tsx">
      <Button variant="ghost" size="icon" onClick={() => setOpen(Math.random() * 100)} data-sentry-element="Button" data-sentry-source-file="intercom.tsx" data-sentry-element="Button" data-sentry-element="Button">
    <svg xmlns="http://www.w3.org/2000/svg" width="48" height="48" viewBox="0 0 24 24" fill="currentColor" stroke="white" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round" className="lucide lucide-message-circle-question" data-sentry-element="svg" data-sentry-source-file="intercom.tsx" data-sentry-element="svg" data-sentry-element="svg"><path d="M7.9 20A9 9 0 1 0 4 16.1L2 22Z" data-sentry-element="path" data-sentry-source-file="intercom.tsx" data-sentry-element="path" data-sentry-element="path" /><path d="M9.09 9a3 3 0 0 1 5.83 1c0 2-3 3-3 3" data-sentry-element="path" data-sentry-source-file="intercom.tsx" data-sentry-element="path" data-sentry-element="path" /><path d="M12 17h.01" data-sentry-element="path" data-sentry-source-file="intercom.tsx" data-sentry-element="path" data-sentry-element="path" /></svg>
      </Button>
    </div>;
}