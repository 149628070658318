"use client";

import { useEffect, useState } from "react";
import Image from "next/image";
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";
import { BLOCKS } from "@contentful/rich-text-types";
import { getAsset, getIframe } from "@/lib/contentful";
import { Accordion, AccordionContent, AccordionItem, AccordionTrigger } from "@/components/ui/accordion";
import { Heading } from "./heading";
import { Button } from "./ui/button";
type FAQItem = {
  sys: {
    id: string;
  };
  title: string;
  slug: string;
  content: any;
};
type FAQSection = {
  sys: {
    id: string;
  };
  title: string;
  label: string;
  items: {
    items: FAQItem[];
  };
};
type FAQProps = {
  sections: FAQSection[];
};
export const Asset = ({
  asset
}: {
  asset: any;
}) => {
  const [image, setImage] = useState<any>();
  useEffect(() => {
    getAsset(asset.data.target.sys.id).then(res => {
      setImage(res);
    });
  }, [asset]);
  if (!asset) return null;
  return image ? <div className="relative aspect-video w-full py-4" data-sentry-component="Asset" data-sentry-source-file="faqs.tsx">
      <Image className="relative aspect-video w-full overflow-hidden" src={image.url} alt="" fill objectFit="contain" data-sentry-element="Image" data-sentry-source-file="faqs.tsx" data-sentry-element="Image" data-sentry-element="Image" />
    </div> : null;
};
export const Iframe = ({
  data
}: {
  data: any;
}) => {
  const [entry, setEntry] = useState<any>();
  useEffect(() => {
    getIframe(data.data.target.sys.id).then(res => {
      setEntry(res);
    });
  }, [data]);
  if (!data) return null;
  return entry ? <div className="aspect-video" data-sentry-component="Iframe" data-sentry-source-file="faqs.tsx">
      <iframe src={entry?.url} width="100%" height="100%" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture" allowFullScreen></iframe>
    </div> : null;
};
export const options = {
  renderNode: {
    [BLOCKS.EMBEDDED_ASSET]: (asset: any) => <Asset asset={asset} />,
    [BLOCKS.EMBEDDED_ENTRY]: (entry: any) => <Iframe data={entry} />
  }
};
const SectionAccordion = ({
  section
}: {
  section: FAQSection;
}) => {
  if (!section) return null;
  if (!section.items.items.length) return null;
  return <Accordion id="faq-accordion" type="single" collapsible className="mt-4 w-full" defaultValue={section.items.items[0].sys.id} onValueChange={value => {
    const trigger = document.getElementById(`trigger-${value}`);
    if (trigger) {
      setTimeout(() => {
        const yOffset = -64;
        const y = trigger.getBoundingClientRect().top + window.scrollY + yOffset;
        window.scrollTo({
          top: y,
          behavior: "smooth"
        });
      }, 200);
    }
  }} data-sentry-element="Accordion" data-sentry-component="SectionAccordion" data-sentry-source-file="faqs.tsx">
      {section.items.items.map((item, idx) => {
      if (!item?.sys) return null;
      return <div key={`faq-section-${idx}-${section?.sys?.id}`}>
            <AccordionItem value={item.sys.id}>
              <AccordionTrigger className="font-bold" id={`trigger-${item.sys.id}`}>
                {item.title}
              </AccordionTrigger>
              <AccordionContent>
                <a href={`#${item.sys.id}`} className="sr-only">
                  Skip to {item.title}
                </a>
                <div className="prose relative flex flex-col gap-4 dark:prose-invert">
                  {documentToReactComponents(item.content.json, options)}
                </div>
              </AccordionContent>
            </AccordionItem>
          </div>;
    })}
    </Accordion>;
};
export const FAQs = (props: FAQProps) => {
  const [currentSection, setCurrentSection] = useState(0);
  const onSectionClick = (idx: number) => () => setCurrentSection(idx);
  return <div className="w-full" data-sentry-component="FAQs" data-sentry-source-file="faqs.tsx">
      <div className="grid grid-cols-2 gap-4 md:grid-cols-4">
        {props.sections.map((section, idx) => <Button key={`faq-section-${idx}`} variant={idx === currentSection ? "default" : "outline"} onClick={onSectionClick(idx)}>
            {section.label}
          </Button>)}
      </div>
      <Heading as="h2" className="my-4 text-xl font-bold text-primary lg:my-8" data-sentry-element="Heading" data-sentry-source-file="faqs.tsx" data-sentry-element="Heading" data-sentry-element="Heading">
        Popular Questions
      </Heading>
      <SectionAccordion section={props.sections[currentSection]} data-sentry-element="SectionAccordion" data-sentry-source-file="faqs.tsx" data-sentry-element="SectionAccordion" data-sentry-element="SectionAccordion" />
    </div>;
};