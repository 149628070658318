'use client';

import posthog from 'posthog-js';
import { PostHogContext, PostHogProvider } from 'posthog-js/react';
import { ReactNode, useContext } from 'react';
posthog.init("phc_uFC3qjgsySwrchFAPE4s5q778PIxEuvmZXMvNdDMTBq", {
  api_host: 'https://us.i.posthog.com',
  autocapture: true,
  capture_pageleave: true,
  capture_pageview: true
});
export const PHProvider = ({
  children
}: {
  children: ReactNode;
}) => {
  return <PostHogProvider client={posthog} data-sentry-element="PostHogProvider" data-sentry-component="PHProvider" data-sentry-source-file="posthog-clientside.tsx">
      {children}
    </PostHogProvider>;
};
export const usePostHog = () => {
  const context = useContext(PostHogContext);
  if (context === undefined) {
    throw new Error('usePostHog must be used within a PHProvider');
  }
  return context.client;
};