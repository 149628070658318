"use client";

import * as React from "react";
import { useTheme } from "next-themes";
import { Button } from "@/components/ui/button";
import { Icons } from "@/components/icons";
export function ThemeToggle() {
  const {
    setTheme,
    theme
  } = useTheme();
  return <Button variant="ghost" size="sm" onClick={() => setTheme(theme === "light" ? "dark" : "light")} data-sentry-element="Button" data-sentry-component="ThemeToggle" data-sentry-source-file="theme-toggle.tsx">
      <Icons.sun className="rotate-0 scale-100 transition-all dark:-rotate-90 dark:scale-0" data-sentry-element="unknown" data-sentry-source-file="theme-toggle.tsx" data-sentry-element="unknown" data-sentry-element="unknown" />
      <Icons.moon className="absolute rotate-90 scale-0 transition-all dark:rotate-0 dark:scale-100" data-sentry-element="unknown" data-sentry-source-file="theme-toggle.tsx" data-sentry-element="unknown" data-sentry-element="unknown" />
      <span className="sr-only">Light/Dark Mode</span>
    </Button>;
}